import React from 'react';
import { graphql } from 'gatsby';
import {
  TranslationsContext,
  TranslationResult,
  translationsFactory,
} from 'settings/i18n';
import PrivacyPageStyles from 'components/PrivacyPageLayout/PrivacyPageLayout';
import EducationNavigation from 'components/EducationNavigation/EducationNavigation';
import Footer from 'components/Footer/Footer';
import SEO from 'components/SEO';
import ClientOnly from 'components/ClientOnly/ClientOnly';
import menuLinks from 'settings/menuLinks';
import { useIsRtl } from 'hooks/useCurrentLocale';

const PrivacyPolicy = ({ data }: { data: TranslationResult }) => {
  const t = translationsFactory({
    wpPage: {
      id: 'translations',
      translation: {
        ...data.education?.translation,
        ...data.homepage?.translation,
        privacyPolicyContent: data.translations.translation.privacyPolicy,
      },
    },
  });

  const isRtl = useIsRtl();

  return (
    <TranslationsContext.Provider value={t}>
      <SEO metadata={data.metadata} url={menuLinks.privacyPolicy} />
      <ClientOnly>
        <EducationNavigation />
        <PrivacyPageStyles
          isRtl={isRtl}
          dangerouslySetInnerHTML={{ __html: t('privacyPolicyContent') }}
        />
        <Footer />
      </ClientOnly>
    </TranslationsContext.Provider>
  );
};

export default PrivacyPolicy;

export const query = graphql`
  query($locale: String!) {
    translations: wpPage(
      slug: { regex: "/\\b(\\w*privacy-policy\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      id
      translation {
        privacyPolicy
      }
    }
    
    metadata: wpPage(
      slug: { regex: "/\\b(\\w*privacy-policy\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...MetaData
    }

    education: wpPage(
      slug: { regex: "/\\b(\\w*education\\w*)+(-\\d+)?\\b$/" }
      language: { locale: { eq: $locale } }
    ) {
      ...HeaderTranslation
    }

    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...HeaderTranslation
      ...FooterTranslation
    }
  }
`;
